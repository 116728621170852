import React, { useState, useEffect } from "react";
import { Client, Messaging, Users, ID, Query } from "node-appwrite";
import "./css/Aconv.css"; // Import CSS for styling
import { useUser } from "./UserContext";

function Aconv() {
  const { user } = useUser();
  const [videoFile, setVideoFile] = useState(null);
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [subscribers, setSubscribers] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [fetchedUserId, setFetchedUserId] = useState("");
  const [fetchedTargetId, setFetchedTargetId] = useState("");
  const [ccUserId, setCcUserId] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserPhone, setNewUserPhone] = useState("");
  const [newUserName, setNewUserName] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [loadingSubscribers, setLoadingSubscribers] = useState(false);
  const topicName = "SELLER"; // Setting the topic name

  // Function to fetch the topic by name and list subscribers
  const fetchSubscribersForSellerTopic = async () => {
    setLoadingSubscribers(true);

    try {
      const client = new Client()
        .setEndpoint("https://cloud.appwrite.io/v1") // Your API Endpoint
        .setProject("65eef2e3e025ba72a15c") // Your project ID
        .setKey(process.env.REACT_APP_API_KEY); // Ensure this key is stored securely

      const messaging = new Messaging(client);

      // Fetch all topics
      const topicsResponse = await messaging.listTopics();
      const topics = topicsResponse.topics;

      // Find the topic with the name "seller"
      const sellerTopic = topics.find((topic) => topic.name === topicName);

      if (!sellerTopic) {
        alert("Topic 'seller' not found!");
        return;
      }

      const sellerTopicId = sellerTopic.$id;

      // Fetch subscribers for the "seller" topic
      const subscribersResponse = await messaging.listSubscribers(
        sellerTopicId
      );
      console.log(subscribersResponse);
      // Filter subscribers with email provider type
      const emailSubscribers = subscribersResponse.subscribers.filter(
        (subscriber) => subscriber.providerType === "email"
      );

      setSubscribers(emailSubscribers);
    } catch (error) {
      console.error("Failed to fetch subscribers:", error);
      alert("Failed to fetch subscribers: " + error.message);
    } finally {
      setLoadingSubscribers(false);
    }
  };
  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type.startsWith("video/") || file.name.endsWith(".mkv"))
    ) {
      setVideoFile(URL.createObjectURL(file)); // Create a URL for the video file
    } else {
      alert("Please select a valid video file.");
    }
  };
  // Function to fetch all users
  const fetchUsers = async () => {
    try {
      const client = new Client()
        .setEndpoint("https://cloud.appwrite.io/v1") // Your API Endpoint
        .setProject("65eef2e3e025ba72a15c") // Your project ID
        .setKey(process.env.REACT_APP_API_KEY); // Ensure this key is stored securely

      const users = new Users(client);

      // Fetch all users with a limit
      const usersResponse = await users.list([Query.limit(300)]);

      // Log usersResponse only once when retrieved
      if (usersResponse.users.length > 0 && user) {
        console.log("Total Users:", usersResponse.total);
        console.log("Current User:", user);
      }

      setUsersList(usersResponse.users);
    } catch (error) {
      console.error("Failed to fetch users:", error);
      alert("Failed to fetch users: " + error.message);
    }
  };

  // Function to fetch the user ID based on the selected email
  const fetchUserIdByEmail = () => {
    const selectedUser = usersList.find((user) => user.email === selectedEmail);

    if (selectedUser) {
      setFetchedUserId(selectedUser.$id);

      // Find the target ID for the "email" providerType
      const emailTarget = selectedUser.targets.find(
        (target) => target.providerType === "email"
      );

      if (emailTarget) {
        setFetchedTargetId(emailTarget.$id); // Set the target ID associated with the email
      } else {
        alert("No email target found for the selected user.");
        setFetchedTargetId("");
      }
    } else {
      alert("No user found with that email.");
      setFetchedUserId("");
      setFetchedTargetId("");
    }
  };

  // Function to create a new user
  const handleCreateUser = async (e) => {
    e.preventDefault();

    if (!newUserEmail || !newUserPhone || !newUserName || !newUserPassword) {
      alert("Please fill in all fields to create a new user.");
      return;
    }

    try {
      const client = new Client()
        .setEndpoint("https://cloud.appwrite.io/v1") // Your API Endpoint
        .setProject("65eef2e3e025ba72a15c") // Your project ID
        .setKey(process.env.REACT_APP_API_KEY); // Ensure this key is stored securely

      const users = new Users(client);

      await users.create(
        ID.unique(),
        newUserEmail,
        newUserPhone,
        newUserPassword,
        newUserName
      );

      alert("User created successfully!");
      fetchUsers(); // Refresh the users list
      setNewUserEmail("");
      setNewUserPhone("");
      setNewUserName("");
      setNewUserPassword("");
    } catch (error) {
      console.error("Failed to create user:", error);
      alert("Failed to create user: " + error.message);
    }
  };

  // Function to send an email to the subscribers of the "seller" topic
  const handleSendEmailToSellerSubscribers = async () => {
    if (subject.trim() === "" || content.trim() === "") {
      alert("Subject and Content cannot be empty!");
      return;
    }

    if (subscribers.length === 0) {
      alert("No subscribers to send email to.");
      return;
    }

    if (!user) {
      alert("User not logged in.");
      return;
    }

    // Find the target ID for the "email" providerType from the current user's targets
    const userEmailTarget = user.targets.find(
      (target) => target.providerType === "email"
    );

    if (!userEmailTarget) {
      alert("No email target found for the current user.");
      return;
    }

    const userTargetId = userEmailTarget.$id;

    // Prepare arrays for user IDs and target IDs
    const userIds = [user.$id];
    const targetIds = [userTargetId];

    // If a CC user ID and target ID were fetched, include them
    if (fetchedUserId && fetchedTargetId) {
      userIds.push(fetchedUserId);
      targetIds.push(fetchedTargetId);
    }

    setIsSending(true);

    try {
      const client = new Client()
        .setEndpoint("https://cloud.appwrite.io/v1") // Your API Endpoint
        .setProject("65eef2e3e025ba72a15c") // Your project ID
        .setKey(process.env.REACT_APP_API_KEY); // Ensure this key is stored securely

      const messaging = new Messaging(client);

      // Use the existing file in the fashion bucket
      const bucketId = process.env.REACT_APP_BUCKET_FASHION_ID;
      const fileId = "66bf35530001e7afc5d6";
      const attachment = `${bucketId}:${fileId}`;

      // Prepare the array of topicIds
      const topicIds = subscribers.map((subscriber) => subscriber.topicId);

      // Send the email with the existing file attached
      await messaging.createEmail(
        ID.unique(), // Unique messageId
        subject, // Subject of the email
        content, // Content of the email
        topicIds, // Topics (you can specify more if needed)
        userIds, // Include both current user ID and CC user ID
        targetIds, // Include both current user target ID and CC target ID
        [], // CC (optional)
        [], // BCC (optional)
        [attachment], // Attachments array with the existing file
        false, // Draft (optional)
        false // HTML (optional)
      );

      alert("Email sent successfully!");
    } catch (error) {
      alert("Failed to send email: " + error.message);
    } finally {
      setIsSending(false);
    }
  };

  // Fetch subscribers and users on component mount
  useEffect(() => {
    if (user) {
      fetchUsers();
    }
  }, [user]);

  useEffect(() => {
    fetchSubscribersForSellerTopic();
  }, []);

  return (
    <div className="aconv-container">
      {/* Display the current logged-in user's email and name */}
      <h1 className="aconv-title">
        Welcome, {user?.name || "User"} ({user?.email || "No Email Available"})
      </h1>

      <h1 className="aconv-title">Send Email to Seller Subscribers</h1>
      <input
        type="text"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        placeholder="Enter email subject"
        className="aconv-input"
      />
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Enter email content here"
        className="aconv-text-area"
      />
      <div>
        <label>Select User to CC:</label>
        <select
          value={selectedEmail}
          onChange={(e) => setSelectedEmail(e.target.value)}
        >
          <option value="">Select an email</option>
          {usersList.map((user, index) => (
            <option key={index} value={user.email}>
              {user.email}
            </option>
          ))}
        </select>
        <button onClick={fetchUserIdByEmail}>Fetch ID</button>
      </div>

      {/* Display the fetched user ID and email target ID */}
      {fetchedUserId && (
        <div>
          <p>
            <strong>Fetched User ID:</strong> {fetchedUserId}
          </p>
          {fetchedTargetId && (
            <p>
              <strong>Email Target ID:</strong> {fetchedTargetId}
            </p>
          )}
        </div>
      )}

      <div>
        <label>CC User Target ID:</label>
        <input
          type="text"
          value={ccUserId}
          onChange={(e) => setCcUserId(e.target.value)}
          placeholder="Enter CC user target ID"
        />
      </div>

      <button
        className="aconv-button"
        onClick={handleSendEmailToSellerSubscribers}
        disabled={isSending} // Only disable when an email is being sent
      >
        {isSending ? "Sending..." : "Send Email"}
      </button>

      <h2 className="aconv-title">Seller Topic Subscribers</h2>
      {loadingSubscribers ? (
        <p>Loading subscribers...</p>
      ) : (
        <ul className="aconv-subscriber-list">
          {subscribers.map((subscriber, index) => (
            <li key={index}>
              {subscriber.targetId} -{" "}
              {subscriber.target?.identifier || "No Identifier Available"}
              <br />
              Topic ID: {subscriber.topicId} <br />
              User ID: {subscriber.userId}
            </li>
          ))}
        </ul>
      )}

      <h2 className="aconv-title">Create New User</h2>
      <form onSubmit={handleCreateUser} className="aconv-form">
        <div className="aconv-form-group">
          <label>Email:</label>
          <input
            type="email"
            value={newUserEmail}
            onChange={(e) => setNewUserEmail(e.target.value)}
            placeholder="Enter user email"
            required
          />
        </div>
        <div className="aconv-form-group">
          <label>Phone:</label>
          <input
            type="tel"
            value={newUserPhone}
            onChange={(e) => setNewUserPhone(e.target.value)}
            placeholder="Enter user phone number"
            required
          />
        </div>
        <div className="aconv-form-group">
          <label>Name:</label>
          <input
            type="text"
            value={newUserName}
            onChange={(e) => setNewUserName(e.target.value)}
            placeholder="Enter user name"
            required
          />
        </div>
        <div className="aconv-form-group">
          <label>Password:</label>
          <input
            type="password"
            value={newUserPassword}
            onChange={(e) => setNewUserPassword(e.target.value)}
            placeholder="Enter a secure password"
            required
            minLength={8}
          />
        </div>
        <button type="submit" className="aconv-button">
          Create User
        </button>
      </form>

      <h2 className="aconv-title">All Users</h2>
      <ul className="aconv-user-list">
        {usersList.map((user, index) => (
          <li key={index}>
            {user.name || "No Name Available"} -{" "}
            {user.email || "No Email Available"}
          </li>
        ))}
      </ul>
      <div className="video-upload-container">
        <h2>Upload and Play Video</h2>
        <input
          type="file"
          accept="video/*"
          onChange={handleVideoUpload}
          className="video-input"
        />
        {videoFile && (
          <video controls width="500" src={videoFile} className="video-player">
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    </div>
  );
}

export default Aconv;
