import React from "react";
import Slider from "react-slick";
function Carousal() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <div>
      <Slider {...settings}>
        <div className="banner">
          <h3>
            <a href="/learnmusic">
              <img src="./assets/banner/leanmusic.jpg" alt="Slide 2" />
            </a>
          </h3>
        </div>
        <div className="banner">
          <h3>
            <a href="/">
              <img src="./assets/banner/newbeats.jpg" alt="Slide 1" />
            </a>
          </h3>
        </div>

        <div className="banner">
          <h3>
            <a href="/beatmaker">
              <img src="./assets/banner/beatmaker.jpg" alt="Slide 3" />
            </a>
          </h3>
        </div>
        <div className="banner">
          <h3>
            <a href="/fashion">
              <img src="./assets/banner/fashion.jpg" alt="Slide 3" />
            </a>
          </h3>
        </div>
        <div className="banner">
          <h3>
            <a href="/releases">
              <img src="./assets/banner/releases.jpg" alt="Slide 4" />
            </a>
          </h3>
        </div>
      </Slider>
    </div>
  );
}

export default Carousal;
