import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./css/Navigation.css";
import { useUser } from "./UserContext";
function Navigation({ show, onClose }) {
  const { user } = useUser();

  const handleClickOutside = (event) => {
    // Check if the click is outside the navigation panel
    if (show && !event.target.closest(".navigation")) {
      onClose(); // Close the navigation
    }
  };

  return (
    <div
      className={`navigation ${show ? "show" : "hide"}`}
      onClick={handleClickOutside}
    >
      {/* <Link to="/" className="nav-link">
        <div className="nav-item">Home</div>
      </Link> */}
      <Link to="/" className="nav-link">
        <div className="nav-item">Tutorials </div>{" "}
      </Link>
      <Link to="/newbeats" className="nav-link">
        <div className="nav-item"> Beats</div>{" "}
      </Link>
      <Link to="/fashion" className="nav-link">
        <div className="nav-item">Fashion</div>
      </Link>
      <Link to="/beatmaker" className="nav-link">
        <div className="nav-item">BeatMaker</div>
      </Link>
      <Link to="/chess" className="nav-link">
        <div className="nav-item">Play Chess</div>{" "}
      </Link>{" "}
      {/* <Link to="/room" className="nav-link">
        <div className="nav-item">Room</div>{" "}
      </Link>{" "} */}
      <Link to="/services" className="nav-link">
        <div className="nav-item">Services</div>
      </Link>
      <Link to="/releases" className="nav-link">
        <div className="nav-item">Releases</div>{" "}
      </Link>{" "}
    </div>
  );
}

export default Navigation;
