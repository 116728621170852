import React, { useEffect, useState } from "react";
import { Databases, Storage } from "appwrite";
import client from "./appwriteConfig";
import "./css/LearnMusic.css"; // Import the CSS file
import { BeatLoader, ClipLoader } from "react-spinners"; // Import loaders
import { useUser } from "./UserContext";
import { FaPlayCircle, FaShoppingCart } from "react-icons/fa";
import Modal from "react-modal"; // Import Modal
import Carousal from "./Carousal";

Modal.setAppElement("#root"); // Set the root element for accessibility

const extractFileName = (fileName) => {
  const parts = fileName.split("_");
  return parts.slice(2).join("_"); // Take everything after the first two parts
};

function LearnMusic() {
  const { user, cart, addToCart, cartLoading, fetchCartData } = useUser();
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [playingVideo, setPlayingVideo] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState(null);

  useEffect(() => {
    if (user) {
      fetchCartData();
    }
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      const database = new Databases(client);
      const response = await database.listDocuments(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_TUTORIALS_ID
      );

      const fetchedVideos = await Promise.all(
        response.documents.map(async (doc) => {
          const videosData = JSON.parse(doc.videos);
          const thumbnailUrls = await Promise.all(
            videosData.map(async (video) => {
              const thumbnailUrl = await new Storage(client).getFileView(
                process.env.REACT_APP_BUCKET_TUTORIALS_ID,
                video.thumbnailId
              );
              return {
                videoId: video.videoId,
                thumbnailUrl: thumbnailUrl.href,
                videoName: extractFileName(video.videoName), // Extract the part of the filename
              };
            })
          );

          return {
            ...doc,
            thumbnailUrls,
            videosData,
            inCart: cart.some((item) => item.productId === doc.$id),
            description: doc.description,
          };
        })
      );

      setLoading(false);
      setVideos(fetchedVideos);
    };

    fetchData();
  }, [cart]); // Depend on cart to refresh inCart status

  const isInCart = (productId) =>
    cart.some((item) => item.productId === productId);

  const handlePlay = async (videoId, isFree) => {
    if (isFree) {
      try {
        const videoUrl = await new Storage(client).getFileView(
          process.env.REACT_APP_BUCKET_TUTORIALS_ID,
          videoId
        );
        setPlayingVideo({ id: videoId, url: videoUrl.href });
      } catch (error) {
        console.error("Failed to fetch video URL", error);
      }
    } else {
      const selectedTutorial = videos.find((video) =>
        video.videosData.some((v) => v.videoId === videoId)
      );
      setSelectedTutorial(selectedTutorial);
      setModalIsOpen(true);
    }
  };

  const handleAddToCart = (tutorial) => {
    if (!isInCart(tutorial.$id)) {
      const tutorialInfo = JSON.stringify({
        tutorialTitle: tutorial.tutorialTitle,
        videoUrl: tutorial.videosData[0].videoId, // Assuming the first video is the main one
        authorName: tutorial.authorName,
        email: user.email,
        thumbnailUrl: tutorial.thumbnailUrls[0].thumbnailUrl, // Assuming the first thumbnail is the main one
        cost: tutorial.cost,
      });

      addToCart({
        ...tutorial,
        TUTORIALinfo: tutorialInfo, // Consolidated tutorial info
        productType: "tutorial", // Set productType to tutorial
      });
    }
  };

  const renderVideos = () => {
    if (loading) {
      return (
        <div className="centered-loader">
          <BeatLoader color="#3498db" />
        </div>
      );
    }

    return videos.map((video, index) => (
      <div key={index}>
        <div className="tutorial-card">
          <div className="tutorial-title">{video.tutorialTitle}</div>
          <p>{video.description}</p>
          <div className="video-card">
            <div className="video-carded">
              {video.thumbnailUrls.map((thumbnail, idx) => (
                <div key={idx} className="thumbnail-container">
                  {playingVideo && playingVideo.id === thumbnail.videoId ? (
                    <video
                      controls
                      autoPlay
                      className="video-playing"
                      controlsList="nodownload"
                    >
                      <source src={playingVideo.url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <>
                      <img
                        src={thumbnail.thumbnailUrl}
                        alt="Thumbnail"
                        className="thumbnail"
                      />
                      <button
                        className="play-button-tut"
                        onClick={() => handlePlay(thumbnail.videoId, idx === 0)}
                      >
                        <FaPlayCircle />
                      </button>
                      <p className="video-name">{thumbnail.videoName}</p>
                    </>
                  )}
                </div>
              ))}
            </div>
            <div className="video-details">
              <div className="details-row">
                <p>Author: {video.authorName}</p>
                <p>Category: {video.category}</p>
                <p>Duration: {video.duration}</p>
              </div>
              <div className="cart-button-tut">
                <p>₹ {video.cost}</p>
                <button
                  className="add-to-cart-button in-cart"
                  onClick={() => handleAddToCart(video)}
                  disabled={isInCart(video.$id)}
                >
                  {cartLoading[video.$id] ? (
                    <ClipLoader size={10} color="#FFF" /> // Spinner shows while loading
                  ) : isInCart(video.$id) ? (
                    "In Cart"
                  ) : (
                    "Add to Cart"
                  )}
                  <FaShoppingCart />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="learn-music-container">
      <Carousal />
      <h2 className="learn-music-header">Learn Music</h2>
      <div className="video-list">{renderVideos()}</div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Buy Now Modal"
        className="buy-now-modal"
        overlayClassName="buy-now-overlay"
      >
        <h2>Buy Now to Access Full Course</h2>
        {selectedTutorial && (
          <div>
            <h3>{selectedTutorial.tutorialTitle}</h3>
            <p>Author: {selectedTutorial.authorName}</p>
            <p>Category: {selectedTutorial.category}</p>
            <p>Duration: {selectedTutorial.duration}</p>
            <p>₹ {selectedTutorial.cost}</p>
            <button
              className="add-to-cart-button in-cart"
              onClick={() => {
                handleAddToCart(selectedTutorial);
                setModalIsOpen(false);
              }}
              disabled={isInCart(selectedTutorial.$id)}
            >
              {cartLoading[selectedTutorial.$id] ? (
                <ClipLoader size={10} color="#FFF" />
              ) : isInCart(selectedTutorial.$id) ? (
                "In Cart"
              ) : (
                "Add to Cart"
              )}
              <FaShoppingCart />
            </button>
          </div>
        )}
        <button onClick={() => setModalIsOpen(false)}>Close</button>
      </Modal>
    </div>
  );
}

export default LearnMusic;
