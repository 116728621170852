import React, { useEffect, useState } from "react";
import { Databases, Query, Storage } from "appwrite";
import { useUser } from "./UserContext";
import client from "./appwriteConfig";
import { BeatLoader } from "react-spinners";
import "./css/Purchased.css";

const Purchased = () => {
  const { user } = useUser();
  const [purchasedItems, setPurchasedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadedVideos, setLoadedVideos] = useState({}); // Track loaded tutorial videos

  useEffect(() => {
    const fetchPurchasedItems = async () => {
      if (!user) return;

      const databases = new Databases(client);
      const storage = new Storage(client);

      try {
        const response = await databases.listDocuments(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_TRANSACTIONS,
          [
            Query.equal("email", user.email),
            Query.equal("paymentStatus", "success"),
          ]
        );

        const items = await Promise.all(
          response.documents.map(async (item) => {
            let cartItems = [];
            try {
              cartItems = JSON.parse(item.cart);
            } catch (error) {
              console.error("Failed to parse cart items:", error);
            }

            const updatedCartItems = await Promise.all(
              cartItems.map(async (product) => {
                // Handle Beats
                if (product.audioUrls) {
                  product.audioUrlLink = await getFileViewUrl(
                    storage,
                    process.env.REACT_APP_BUCKET_BEATS_ID,
                    product.audioUrls
                  );

                  if (product.imageUrls) {
                    product.albumArtLink = await getFileViewUrl(
                      storage,
                      process.env.REACT_APP_BUCKET_BEATS_ID,
                      product.imageUrls
                    );
                  }
                }

                // Handle Tutorials
                if (product.productType === "tutorial" && product.productId) {
                  const tutorialData = await databases.getDocument(
                    process.env.REACT_APP_DATABASE_ID,
                    process.env.REACT_APP_COLLECTION_TUTORIALS_ID,
                    product.productId
                  );

                  if (tutorialData.videos) {
                    const videos = JSON.parse(tutorialData.videos);
                    product.videos = await Promise.all(
                      videos.map(async (video) => {
                        const videoUrl = await storage.getFileView(
                          process.env.REACT_APP_BUCKET_TUTORIALS_ID,
                          video.videoId
                        );
                        const thumbnailUrl = await storage.getFileView(
                          process.env.REACT_APP_BUCKET_TUTORIALS_ID,
                          video.thumbnailId
                        );
                        return {
                          ...video,
                          videoUrl: videoUrl.href,
                          thumbnailUrl: thumbnailUrl.href,
                        };
                      })
                    );
                  }
                }

                // Handle Fashion
                if (product.fashionUrls) {
                  product.fashionUrlLink = await getFileViewUrl(
                    storage,
                    process.env.REACT_APP_BUCKET_FASHION_ID,
                    product.fashionUrls
                  );
                }

                return product;
              })
            );

            return { ...item, cart: updatedCartItems };
          })
        );

        setPurchasedItems(items);
      } catch (error) {
        console.error("Failed to fetch purchased items:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPurchasedItems();
  }, [user]);

  const getFileViewUrl = async (storage, bucketId, fileId) => {
    if (!fileId) {
      console.error("Missing fileId");
      return null;
    }

    try {
      const response = await storage.getFileView(bucketId, fileId);
      return response.href;
    } catch (error) {
      console.error("Failed to get file view URL:", error);
      return null;
    }
  };

  const loadVideo = async (videoId) => {
    const storage = new Storage(client);

    try {
      const response = await storage.getFileView(
        process.env.REACT_APP_BUCKET_TUTORIALS_ID,
        videoId
      );

      // Add the loaded video URL to the state
      setLoadedVideos((prev) => ({
        ...prev,
        [videoId]: response.href, // Store the URL for this videoId
      }));
    } catch (error) {
      console.error(`Failed to load video for ID ${videoId}:`, error);
    }
  };

  return (
    <div className="purchased-container">
      <h2>Purchased Items</h2>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <BeatLoader color="#3498db" />
        </div>
      ) : (
        <>
          {purchasedItems.length === 0 ? (
            <div>No purchased items found.</div>
          ) : (
            <div className="purchased-list">
              {purchasedItems.map((item) => (
                <div className="purchased-item" key={item.$id}>
                  <div className="purchesed-details">
                    {" "}
                    <h3>Cart ID: {item.$id}</h3>
                    <p>Total Price: {item.totalPrice}</p>
                    <p>
                      Purchase Date:{" "}
                      {new Date(item.$createdAt).toLocaleDateString()}
                    </p>
                  </div>

                  <div className="cart-items">
                    {item.cart.map((product, index) => (
                      <div className="cart-item" key={index}>
                        <div className="pur-cartitem">
                          <p>Cost: {product.cost}</p>
                          <p>Type: {product.productType}</p>
                        </div>

                        <h4 className="pur-productName">
                          {product.productName}
                        </h4>

                        {/* Beats */}
                        {product.audioUrlLink && (
                          <div className="purchased-audio-section">
                            {product.albumArtLink && (
                              <img
                                src={product.albumArtLink}
                                alt="Album Art"
                                className="purchased-album-art-image"
                              />
                            )}
                            <div className="neon-audio-container">
                              <audio
                                controls
                                className="purchased-audio-player"
                              >
                                <source
                                  src={product.audioUrlLink}
                                  type="audio/mp3"
                                />
                                Your browser does not support the audio element.
                              </audio>
                            </div>
                          </div>
                        )}

                        {/* Tutorials */}
                        {product.productType === "tutorial" &&
                          product.videos && (
                            <div className="tutorial-videos-container">
                              {product.videos.map((video, idx) => (
                                <div
                                  key={idx}
                                  className="tutorial-thumbnail-container"
                                >
                                  <div className="thumbnail-wrapper">
                                    {loadedVideos[video.videoId] ? (
                                      <video
                                        controls
                                        className="purchased-video-player"
                                      >
                                        <source
                                          src={loadedVideos[video.videoId]}
                                          type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        element.
                                      </video>
                                    ) : (
                                      <>
                                        <img
                                          src={video.thumbnailUrl}
                                          alt={video.videoName}
                                          className="thumbnail-image"
                                        />
                                        <button
                                          className="play-button"
                                          onClick={() =>
                                            loadVideo(video.videoId)
                                          }
                                        >
                                          ▶
                                        </button>
                                      </>
                                    )}
                                  </div>
                                  <h5>{video.videoName}</h5>
                                </div>
                              ))}
                            </div>
                          )}

                        {/* Fashion */}
                        {product.fashionUrlLink && (
                          <img
                            src={product.fashionUrlLink}
                            alt="Fashion Item"
                            className="purchased-product-image"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Purchased;
