import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useUser } from "./UserContext";
import ClipLoader from "react-spinners/ClipLoader";
import "./css/Checkout.css";

const statesOfIndia = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
];

const Checkout = () => {
  const location = useLocation();
  const { cart } = location.state || {};
  const { user } = useUser();
  const [loadingPay, setLoadingPay] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const calculateTotalPrice = (cart) => {
    return cart.reduce(
      (total, item) =>
        total + Number(item.totalCost || item.cost * (item.quantity || 1)),
      0
    );
  };

  const totalPrice = calculateTotalPrice(cart);

  const groupItemsByProductType = (cart) => {
    return cart.reduce((groups, item) => {
      const type = item.productType;
      if (!groups[type]) {
        groups[type] = [];
      }
      groups[type].push(item);
      return groups;
    }, {});
  };

  const groupedItems = groupItemsByProductType(cart);
  const hasFashionItems =
    groupedItems.fashion && groupedItems.fashion.length > 0;

  const handlePayment = async (values) => {
    setIsProcessing(true);
    setLoadingPay(true); // Show spinner
    try {
      const txnid = `TXN${Date.now()}`; // Unique transaction ID
      const productInfo = cart.map((item) => item.productName).join(", "); // Concatenate product names

      // Request hash generation from backend
      const hashResponse = await fetch(
        "https://api.hxabyte.com/generate-hash",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            key: "3MMJeP",
            txnid,
            amount: totalPrice,
            email: user.email,
            firstname: user.name.split(" ")[0] || user.name,
            productinfo: productInfo,
            phone: values.mobileNumber,
            cart,
          }),
        }
      );

      const hashData = await hashResponse.json();
      if (hashData.success) {
        // Create and submit the form dynamically
        const form = document.createElement("form");
        form.action = "https://secure.payu.in/_payment";
        form.method = "POST";
        form.target = "_self"; // Opens in the same tab

        // Add required fields to the form
        const fields = {
          key: "3MMJeP",
          txnid,
          amount: totalPrice,
          email: user.email,
          firstname: user.name.split(" ")[0] || user.name,
          lastname: user.name.split(" ")[1] || "",
          productinfo: productInfo,
          surl: `https://api.hxabyte.com/verify-status/${txnid}`,
          furl: `https://api.hxabyte.com/verify-status/${txnid}`,

          phone: values.mobileNumber,
          hash: hashData.hash, // Add hash from backend
        };

        for (const [key, value] of Object.entries(fields)) {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = key;
          input.value = value;
          form.appendChild(input);
        }

        document.body.appendChild(form);
        form.submit(); // Submit the form to PayU
        form.remove();
      } else {
        alert("Failed to generate hash. Please try again.");
      }
    } catch (error) {
      console.error("Error during payment:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setIsProcessing(false);
      setLoadingPay(false); // Hide spinner
    }
  };

  return (
    <div className="checkout-container">
      <div className="form-container-checkout">
        <h2 className="title-checkout">Checkout</h2>
        <div>
          {cart && cart.length > 0 ? (
            <div>
              {/* <h3 className="cart-title-checkout">Cart Items:</h3> */}
              {Object.keys(groupedItems).map((type) => (
                <div key={type}>
                  <h4 className="cart-section-title-checkout">
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </h4>
                  <ul className="cart-item-list-checkout">
                    {groupedItems[type].map((item, index) => (
                      <li key={index} className="cart-item-checkout">
                        <span className="product-name-checkout">
                          {item.productName}
                          {item.productType === "fashion" &&
                            ` x ${item.quantity}`}
                        </span>
                        <span className="product-cost-checkout">
                          {item.productType === "fashion"
                            ? `₹${item.cost} x ${item.quantity} = ₹${
                                item.cost * item.quantity
                              }`
                            : `₹${item.cost}`}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              <p className="total-price-checkout">Total Price: ₹{totalPrice}</p>
            </div>
          ) : (
            <p>No items in the cart.</p>
          )}
        </div>
        <div>
          <h3 className="payment-title-checkout">Payment Details</h3>
          <Formik
            initialValues={{
              mobileNumber: "",
              address: {
                flat: "",
                street: "",
                area: "",
                city: "",
                state: "",
                postalCode: "",
              },
            }}
            validate={(values) => {
              const errors = {};
              if (!/^[0-9]+$/.test(values.mobileNumber)) {
                errors.mobileNumber = "Only numbers are allowed";
              }
              if (hasFashionItems) {
                if (!values.address.flat) {
                  errors.address = { ...errors.address, flat: "Required" };
                }
                if (!values.address.street) {
                  errors.address = { ...errors.address, street: "Required" };
                }
                if (!values.address.area) {
                  errors.address = { ...errors.address, area: "Required" };
                }
                if (!values.address.city) {
                  errors.address = { ...errors.address, city: "Required" };
                }
                if (!values.address.state) {
                  errors.address = { ...errors.address, state: "Required" };
                }
                if (!values.address.postalCode) {
                  errors.address = {
                    ...errors.address,
                    postalCode: "Required",
                  };
                } else if (!/^[0-9]+$/.test(values.address.postalCode)) {
                  errors.address = {
                    ...errors.address,
                    postalCode: "Only numbers are allowed",
                  };
                }
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              handlePayment(values);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <label className="form-label-checkout">Mobile Number</label>
                <Field
                  className="input-field-checkout"
                  type="text"
                  name="mobileNumber"
                  required
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
                <ErrorMessage
                  name="mobileNumber"
                  component="div"
                  className="error-message-checkout"
                />

                {hasFashionItems && (
                  <>
                    <h3 className="address-title-checkout">Address Details</h3>
                    <label className="form-label-checkout">Flat/Door No.</label>
                    <Field
                      className="input-field-checkout"
                      type="text"
                      name="address.flat"
                      required
                    />
                    <ErrorMessage
                      name="address.flat"
                      component="div"
                      className="error-message-checkout"
                    />

                    <label className="form-label-checkout">Street</label>
                    <Field
                      className="input-field-checkout"
                      type="text"
                      name="address.street"
                      required
                    />
                    <ErrorMessage
                      name="address.street"
                      component="div"
                      className="error-message-checkout"
                    />

                    <label className="form-label-checkout">Area</label>
                    <Field
                      className="input-field-checkout"
                      type="text"
                      name="address.area"
                      required
                    />
                    <ErrorMessage
                      name="address.area"
                      component="div"
                      className="error-message-checkout"
                    />

                    <label className="form-label-checkout">City</label>
                    <Field
                      className="input-field-checkout"
                      type="text"
                      name="address.city"
                      required
                    />
                    <ErrorMessage
                      name="address.city"
                      component="div"
                      className="error-message-checkout"
                    />

                    <label className="form-label-checkout">State</label>
                    <Field
                      as="select"
                      className="select-field-checkout"
                      name="address.state"
                      required
                    >
                      <option value="" label="Select state" />
                      {statesOfIndia.map((state) => (
                        <option key={state} value={state} label={state} />
                      ))}
                    </Field>
                    <ErrorMessage
                      name="address.state"
                      component="div"
                      className="error-message-checkout"
                    />

                    <label className="form-label-checkout">Postal Code</label>
                    <Field
                      className="input-field-checkout"
                      type="text"
                      name="address.postalCode"
                      required
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                      }
                    />
                    <ErrorMessage
                      name="address.postalCode"
                      component="div"
                      className="error-message-checkout"
                    />
                  </>
                )}

                <button
                  className="submit-button-checkout"
                  type="submit"
                  disabled={loadingPay || isSubmitting}
                >
                  {loadingPay ? (
                    <ClipLoader
                      color="#ffffff"
                      loading={loadingPay}
                      size={20}
                    />
                  ) : (
                    "Proceed to Pay NOW"
                  )}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
